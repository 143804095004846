<template>
	<div class="page">
		<Tables ref="xTable" :tableName="tableName" :tableData="tableData" :totalPage="totalPage" :isLoading="isLoading"
			:searchFormData="searchFormData" :showAdd="true" :showRefresh="true" :showSearch="true" :showSeq="false" @getList="getList"
			@clearSearch="clearSearch" @addItemInTable="whileAdd">
			<el-form-item slot="search-item">
				<el-input size="small" v-model="searchFormData.fans_id" style="width: 120px;" clearable placeholder="用户ID"></el-input>
			</el-form-item>
			<el-form-item slot="search-item">
				<el-input size="small" v-model="searchFormData.phone" clearable placeholder="请输入手机号查询"></el-input>
			</el-form-item>
			<vxe-table-column slot="table-item" field="fans_id" title="用户ID">
			</vxe-table-column>
			<vxe-table-column slot="table-item" field="phone" title="手机号">
			</vxe-table-column>
			<vxe-table-column slot="table-item" field="status" title="状态">
				<template v-slot="{ row }">
					<span>{{row.status == 1 ? '正常' : '删除'}}</span>
				</template>
			</vxe-table-column>
			<vxe-table-column slot="table-item" field="create_time" title="创建时间">
			</vxe-table-column>
			<vxe-table-column slot="table-item" title="操作">
				<template v-slot="{ row }">
					<el-button size="small" plain @click="deleteOne(row)" v-if="$hasAccess('user.fans/whitedelete') && row.status == 1">删除</el-button>
				</template>
			</vxe-table-column>
		</Tables>
		<!-- 添加 -->
		<el-dialog title="添加白名单" :visible.sync="detailDialogShow" :close-on-click-modal="false"
			:close-on-press-escape="false" width="800px">
			<el-input type="textarea" :rows="2" placeholder="一行一个用户ID" v-model="fans_ids"></el-input>
			<div slot="footer" class="dialog-footer">
				<el-upload style="display: inline-block;margin-right: 10px;" action="" accept=".xls,.xlsx" :show-file-list="false" :auto-upload="false" :on-change="selectFile">
					<el-button slot="trigger" size="small" type="primary">选取文件</el-button>
				</el-upload>
				<el-button size="small" plain icon="el-icon-check" @click="whiteSave">保 存</el-button>
				<el-button size="small" plain icon="el-icon-close" @click="closeDialog">取 消</el-button>
			</div>
		</el-dialog>
	</div>
</template>
<script>
	import Tables from '@/components/tables'
	import XLSX from "xlsx"

	export default {
		name: 'user-fans-list',
		components: {
			Tables
		},
		data() {
			return {
				// 表格信息
				tableName: '用户列表',
				isLoading: false,
				tableData: [],
				totalPage: 0,
				searchFormData: {},
				// 详情
				detailDialogShow: false,
				fans_ids: '',
			}
		},
		mounted: function() {
			//this.searchFormData.searchType = this.searchType.name.value;
			//this.getGroupList();
		},
		computed: {

		},
		methods: {
			// 表格列表
			async getList(currentPage, pageSize) {
				let params = Object.assign({
					token: this.$store.state.user.token,
					page: currentPage,
					size: pageSize
				}, this.searchFormData);
				this.isLoading = true;
				const res = await this.$api.Fans.white.list(params);
				this.tableData = res.data;
				this.totalPage = res.total
				this.isLoading = false
			},
			// 重置搜索
			clearSearch() {
				this.searchFormData = {}
			},
			// 关闭弹窗
			closeDialog() {
				this.detailDialogShow = false;
			},
			// 删除白名单
			async deleteOne(row) {
				this.$confirm('是否确定删除白名单?', '提示', {
					cancelButtonClass: 'btn-custom-cancel',
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(async ()=>{
					const params = {
						token: this.$store.state.user.token,
						fans_id: row.fans_id
					}
					await this.$api.Fans.white.delete(params);
					this.$refs.xTable.refreshTable();
				});
			},
			// 显示添加白名单
			whileAdd(){
				this.fans_ids = "";
				this.detailDialogShow = true;
			},
			// 显示添加白名单
			async whiteSave(){
				//console.log(this.fans_ids);
				if(this.fans_ids.length < 1){
					this.$message('必须输入用户');
					return;
				}
				let flst = this.fans_ids.split("\n");
				//console.log(flst);
				const params = {
					token: this.$store.state.user.token,
					fans_ids: flst
				}
				let res = await this.$api.Fans.white.add(params);
				this.$message('成功添加了 '+res.num+" 条记录");
				this.detailDialogShow = false;
				this.$refs.xTable.refreshTable();
			},
			// 选择文件
			async selectFile(file, fileList){
				var reader = new FileReader();
				var _this = this;
				reader.onload = function (e) {
					var data = e.target.result;
					var workbook = XLSX.read(data, {type: 'binary'});
					var first_worksheet = workbook.Sheets[workbook.SheetNames[0]];
					var jsonArr = XLSX.utils.sheet_to_json(first_worksheet, {header:1});
					for(let v of jsonArr){
						_this.fans_ids += v[0] + "\n";
					}
					_this.fans_ids = _this.fans_ids.substring(0, _this.fans_ids.length-1);
				};
				reader.readAsBinaryString(file.raw);
			}
		}
	}
</script>
<style lang="scss" scoped>
</style>
